import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loader from "./Component/Loader";
import Error404 from "./Error404";
import { toast, ToastContainer } from "react-toastify";
import ForgotPass from "./Component/Admin/ForgotPass";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreaters } from "./Redux";

const Tracker = lazy(() => import("./Component/Tracker/Tracker"));
const LinkInfoOrder = lazy(() => import("./Component/Tracker/LinkInfoOrder"));
const Unsubscribed = lazy(() => import("./Component/Tracker/Unsubscribed"));

const Login = lazy(() => import("./Component/Admin/Login"));
// const ResetPass = lazy(() => import("./Component/Admin/ResetPass"));
const ImportSheet = lazy(() => import("./Component/Admin/ImportSheet"));
// const OrderHistory = lazy(() => import("./Component/Admin/OrderHistory"));
const OrderHistoryNew = lazy(() => import("./Component/Admin/OrderHistoryNew"));

const Products = lazy(() => import("./Component/Admin/Products"));
const EditProducts = lazy(() => import("./Component/Admin/EditProducts"));
const AddProducts = lazy(() => import("./Component/Admin/AddProducts"));

export default function Root() {
  const { accessToken } = useSelector((state) => state.stateVals);
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  useEffect(() => {
    if (location.pathname.match(/\/{2,}/)) {
      if (location.pathname.indexOf("admin") > -1) {
        window.location.href = "/admin";
      } else {
        window.location.href = "/";
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      if (accessToken) {
        const decoded = jwtDecode(accessToken);
        const currentTime = Math.floor(new Date().getTime() / 1000);
        if (currentTime > decoded.exp) {
          toast.error("Request time out!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          userActions.logOut("adminLogOut");

          navigate("/admin");
        } else if (
          location.pathname === "/admin" ||
          location.pathname === "/admin-forgotpassword"
        ) {
          navigate("/admin-view-orders");
        }
      } else {
        if (
          location.pathname !== "/admin" &&
          location.pathname !== "/admin-forgotpassword" &&
          !location.pathname.startsWith("/admin/reset-password/")
        ) {
          navigate("/admin");
        }
      }
    }
    const handleChunkError = (event) => {
      if (event.message && event.message.includes("Loading chunk")) {
        console.warn("Chunk load failed. Reloading app...");
        window.location.reload();
      }
    };

    window.addEventListener("error", handleChunkError);

    // Cleanup the event listener on return
    return () => {
      window.removeEventListener("error", handleChunkError);
    };
  }, [location.pathname]);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Tracker />} />
          <Route path="/check" element={<LinkInfoOrder />} />
          <Route path="/unsubscribe" element={<Unsubscribed />} />
          <Route path="/admin" element={<Login />} />
          {/* <Route path="/admin-forgotpassword" element={<ForgotPass />} />
          <Route
            path="/admin/reset-password/:token"
            element={<ResetPass />}
            caseSensitive={true}
          /> */}
          <Route path="/import-orders" element={<ImportSheet />} />
          <Route path="/admin-view-orders" element={<OrderHistoryNew />} />
          {/* <Route path="/admin-view-orders-new" element={<OrderHistory />} /> */}
          <Route path="/admin-products" element={<Products />} />
          <Route path="/admin-add-products" element={<AddProducts />} />
          <Route path="/admin-edit-products/:id" element={<EditProducts />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
      {location.pathname.match(/\/{2,}/) ? (
        <div className="white-screen"></div>
      ) : null}
    </>
  );
}
