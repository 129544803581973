import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let userData = JSON.parse(secureLocalStorage.getItem("adminInfo"));

const accessToken = userData?.accessToken;
const user_id = userData?.user_id;
const name = userData?.name;

if (!userData) {
  userData = {};
}

const initialState = {
  accessToken: accessToken ? accessToken : null,
  user_id: user_id ? user_id : null,
  name: name ? name : null,
};

const reducer = (state = initialState, action) => {
  if (action.type === "adminLogin") {
    userData.accessToken = action.payload.accessToken;
    userData.user_id = action.payload.user_id;
    userData.name = action.payload.name;

    secureLocalStorage.setItem("adminInfo", JSON.stringify(userData));

    return {
      ...state,
      accessToken: action.payload.accessToken,
      user_id: action.payload.user_id,
      name: action.payload.name,
    };
  } else if (action.type === "adminLogOut") {
    secureLocalStorage.removeItem("adminInfo");

    return {
      ...state,
      accessToken: null,
      user_id: null,
      name: null,
    };
  } else {
    return state;
  }
};
export default reducer;
