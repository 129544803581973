import axios from "axios";
const API_URL = "https://tracking-api.otbdevstack.com/v1/";
const getOrder = (values) => {
  return axios.post(
    `${API_URL}voucher-info`,
    {
      certificate: values.field2,
      order_number: values.field1,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const getOrderByInfo = (values) => {
  return axios.post(
    `${API_URL}basic-info`,
    {
      fname: values.fname,
      lname: values.lname,
      email: values.email,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const adminLogin = (values) => {
  return axios.post(
    `${API_URL}login`,
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const adminForgotPass = (values) => {
  return axios.post(
    `${API_URL}forgot-password`,
    {
      email: values.username,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const resetAdminPassword = (pass, token) => {
  return axios.put(
    API_URL + "update-password/" + token,
    {
      password: pass,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const resetToekenVerify = (token) => {
  return axios.get(
    API_URL + "check-forgot-token/" + token,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const uploadOrders = (formData) => {
  return axios.post(`${API_URL}import-sheet`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const updateOrders = (values, id, adminId) => {
  return axios.put(
    `${API_URL}pavlina-record-track/${id}`,
    {
      adminId: adminId,
      first_name: values.fname,
      last_name: values.lname,
      certificate: values.certificate,
      city: values.city,
      email: values.email,
      company: values.company,
      job: values.job,
      state: values.state,
      zip: values.zip,
      account: values.account,
      product: values.product,
      prod_qty: values.qty,
      price: values.price,
      expiry_date: values.expiry,
      address1: values.address,
      value: values.value,
      redemption_date: values.redeemp,
      delivery_date: values.delivery,
      order_number: values.order_no,
      order_status: values.status,
      tracking_number: values.tracking_no,
      shipping_provider: values.provider,
      phone: values.phone,
      address2: values.address2,
      sku: values.sku,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const listOrdered = (limit, page, values, order_status) => {
  return axios.get(
    `${API_URL}list-orders?limit=${limit}&page=${page}${
      order_status ? "&order_status=" + order_status : ""
    }${
      values?.fieldtype && values?.searchval
        ? "&" + values.fieldtype + "=" + values.searchval
        : ""
    }`,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const listOrderedNew = (limit, page, values) => {
  return axios.get(
    `${API_URL}pavlina-record-track?limit=${limit}&page=${page}&order_status=${values.status}&certificate=${values.certificate}&job=${values.job}&state=${values.state}&city=${values.city}&email=${values.email}&phone=${values.phone}&value=${values.value}&zip=${values.zip}&address1=${values.address}&address2=${values.address2}&company=${values.company}&first_name=${values.fname}&expiry_date=${values.expiry}&last_name=${values.lname}&shipping_provider=${values.provider}&price=${values.price}&redemption_date=${values.redeemp}&order_number=${values.order_no}&account=${values.account}&prod_name=${values.product}&delivery_date=${values.delivery}&prod_qty=${values.qty}&prod_sku=${values.sku}&tracking_number=${values.tracking_no}`,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const listProducts = (limit, page, values) => {
  return axios.get(
    `${API_URL}list-products?limit=${limit}&page=${page}${
      values?.fieldtype && values?.searchval
        ? "&" + values.fieldtype + "=" + values.searchval
        : ""
    }`,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const listProductById = (id) => {
  return axios.get(
    `${API_URL}product/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const addProduct = (formData) => {
  return axios.post(`${API_URL}add-product`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const uploadProduct = (formData, id) => {
  return axios.post(`${API_URL}update-product/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const listAllProducts = () => {
  return axios.get(`${API_URL}all-products`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const listAllAccountants = () => {
  return axios.get(`${API_URL}all-accounts`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getProductInfoById = (id) => {
  return axios.get(`${API_URL}pavlina-certificate-track/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const deleteAllOrder = (id) => {
  return axios.delete(`${API_URL}delete-all-orders`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      deleted_by: id,
    },
  });
};

const deleteProductsById = (id, uId) => {
  return axios.delete(`${API_URL}product/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      adminId: uId,
    },
  });
};

const emailSendForOrder = (id, status) => {
  return axios.get(`${API_URL}send-email/${id}/${status}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const unsubscribeEmail = (id) => {
  return axios.put(
    `${API_URL}unsubscribe/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const AdminListService = {
  getOrder,
  getOrderByInfo,
  adminLogin,
  adminForgotPass,
  resetAdminPassword,
  resetToekenVerify,
  uploadOrders,
  listOrdered,
  listProducts,
  listProductById,
  listAllProducts,
  listAllAccountants,
  listOrderedNew,
  getProductInfoById,
  updateOrders,
  deleteAllOrder,
  deleteProductsById,
  uploadProduct,
  addProduct,
  emailSendForOrder,
  unsubscribeEmail,
};

export default AdminListService;
